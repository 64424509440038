export enum CookieNames {
    AuthToken = 'Authorization',
    ProjectToken ='Project-Token',
    Language = 'Language',
    Sid = 'Sid',
    GeoMessageWarned = 'GeoWarned',
    Device = 'x-device-by',
    ContentType = 'Content-Type',
    Accept = 'Accept',
    // Adding by server when auth goes by number
    PHPSession = 'PHPSESSID',
}

export enum Links {
    StoreGoogle = 'https://play.google.com/store/apps/details?id=by.life.dating2',
}

export enum VK {
    state = 'state=VK',
    authLink = 'https://oauth.vk.com/authorize',
    apiLink = 'https://api.vk.com/method/users.get',
    reqFields = 'first_name,photo_max_orig,city,sex,bdate',
    appID = '51625671',
    apiVersion = '5.131',
}

export enum OK {
    state = 'state=OK',
    authLink = 'https://connect.ok.ru/oauth/authorize',
    apiLink = 'https://api.ok.ru/api/users/getCurrentUser',
    reqFields = 'birthday,first_name,gender,pic_full,location',
    appKey = 'CCOMBCLGDIHBABABA',
    appID = '512001907681',
}

export enum UserStatus {
    Authorized = 'Authorized',
    Guest = 'Guest',
}

export enum Language {
    Ru = 'ru',
    Kz = 'kz',
    En = 'en',
    Default = 'ru',
}

export enum GalleryImg {
    minSize = 51200,
    maxSize = 10485760,
    formats = 'jpg/jpeg/png',
}

export enum MessageImg {
    minSize = 0,
    maxSize = 10485760,
    formats = 'jpg/jpeg/png',
}

export enum MessageVoice {
    maxSize = 10485760,
    maxDuration = 300000,
}

export enum TextLength {
    chat = 1000,
    bio = 80,
    feedback = 200,
    name = 16,
}

export enum Defaults {
    birthday = '1991-09-11',
    ModeratorId = 0,
}

export const RedirectErrors = [401, 402, 403, 409, 429, 500];

export const LP_STATUS_4 = '4';         // Blacklist error
export const LP_STATUS_12 = '12';       // Subscribe error

export const ASTANA_CITY_GEO:[number, number] = [53.902296, 27.561862];