import React, { useEffect, useState, useContext, createContext, FC, Dispatch, SetStateAction } from 'react';
import { chatAPI, TypeOfMessage, IFriend } from 'api';
import { Defaults } from 'config/constants';

export interface ISelectedMessage {
    type: TypeOfMessage
    id: number
    content: string | null
    self: boolean
    sender: string
}

interface IChatContext {
    friend: IFriend
    setFriend: Dispatch<SetStateAction<IFriend>>
    selectedMsg: ISelectedMessage[]
    selectMessage: (msg:ISelectedMessage) => void
    repliedMsg: ISelectedMessage | null
    replyMessage: (msg:ISelectedMessage) => void
    editedMsg: ISelectedMessage | null
    editMessage: (msg:ISelectedMessage) => void
    deleteMessage: () => void
    clearMessage: () => void
    closeHeader: () => void
    toggleAudio: (audio:HTMLAudioElement) => void
    events: any[]
    clearEvents: () => void
}

const ChatContext = createContext<IChatContext>({} as IChatContext);

export const useChat = () => useContext(ChatContext);

export const ChatProvider:FC = ({ children }) => {
    const [events, setEvents] = useState<any[]>([]);

    const clearEvents = () => {
        setEvents([]);
    }

    const [deleteMsg] = chatAPI.useDeleteMessageMutation({
        fixedCacheKey: 'shared-delete-message',
    });

    const [friend, setFriend] = useState<IFriend>({} as IFriend);
    const [selectedMsg, setSelectedMsg] = useState<ISelectedMessage[]>([]);
    const [editedMsg, setEditedMsg] = useState<ISelectedMessage|null>(null);
    const [repliedMsg, setRepliedMsg] = useState<ISelectedMessage|null>(null);
    const [currentAudio, setCurrentAudio] = useState<HTMLAudioElement|null>(null);

    const toggleAudio = (audio:HTMLAudioElement) => {
        if (audio.src !== currentAudio?.src) {
            currentAudio?.pause();
            setCurrentAudio(audio);
        }
    }

    const selectMessage = (msg:ISelectedMessage) => {
        const isSelfSelected = selectedMsg.find((m) => m.self && !msg.self);
        const isFriendSelected = selectedMsg.find((m) => !m.self && m.id !== msg.id);

        if (isSelfSelected || isFriendSelected) {
            return;
        }

        if (!editedMsg && !repliedMsg && !(friend.id === Defaults.ModeratorId)) {
            selectedMsg.find((i) => i.id === msg.id)
                ? setSelectedMsg((state) => state.filter((i) => (i.id !== msg.id)))
                : setSelectedMsg((state) => [
                    ...state,
                    {
                        id: msg.id,
                        type: msg.type,
                        content: msg.content,
                        self: msg.self,
                        sender: msg.sender ,
                    }]
                );
        }
    };

    const editMessage = (msg:ISelectedMessage) => {
        setEditedMsg(msg);
        closeHeader();
    }

    const replyMessage = (msg:ISelectedMessage) => {
        setRepliedMsg(msg);
        closeHeader();
    }

    const deleteMessage = () => {
        const messagesId = selectedMsg.map((i) => i.id);
        deleteMsg({ ids: messagesId });
    }

    const clearMessage = () => {
        setEditedMsg(null);
        setRepliedMsg(null);
    }

    const closeHeader = () => {
        setSelectedMsg([]);
    }

    useEffect(() => {
        const evtSource = new EventSource("/api/v1/sse", {
            withCredentials: true
        });

        evtSource.onerror = (err) => {
            console.log('---', err);
        };

        evtSource.addEventListener('message', (e) => {
            // console.log(JSON.parse(JSON.parse(e.data)?.data?.body));
            setEvents((prev) => {
                return [...prev, JSON.parse(JSON.parse(e.data)?.data?.body)];
            })
        });

        evtSource.addEventListener('message_delete', (e) => {
            // console.log(JSON.parse(JSON.parse(e.data)?.data?.body));
            setEvents((prev) => {
                return [...prev, JSON.parse(JSON.parse(e.data)?.data?.body)];
            })
        });

        evtSource.addEventListener('message_edit', (e) => {
            // console.log(JSON.parse(JSON.parse(e.data)?.data?.body));
            setEvents((prev) => {
                return [...prev, JSON.parse(JSON.parse(e.data)?.data?.body)];
            })
        });

        return () => {
            evtSource.close();
        }
    }, []);

    return (
        <ChatContext.Provider value={{
            friend, setFriend,
            selectedMsg, selectMessage,
            editedMsg, editMessage,
            repliedMsg, replyMessage,
            clearMessage, deleteMessage,
            closeHeader, toggleAudio,
            events, clearEvents,
        }}>
            { children }
        </ChatContext.Provider>
    );
}